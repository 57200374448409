import React, { useState, useRef, useEffect } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";

const initialData = {
  ELA: [
    {
      id: "RI.4.2",
      description:
        "Determine the main idea of a text and explain how it is supported by key details; summarize the text.",
      skills: ["Summarizing Text", "Main Idea", "Key Details"],
    },
    {
      id: "RI.4.3",
      description:
        "Explain events, procedures, ideas, or concepts in a historical, scientific, or technical text, including what happened and why, based on specific information in the text.",
      skills: ["Cause & Effect", "Sequencing", "Summarizing Text", "Inference"],
    },
    {
      id: "RL.4.2",
      description:
        "Determine a theme of a story, drama, or poem from details in the text; summarize the text.",
      skills: ["Summarizing Text", "Theme"],
    },
  ],
  "Soc. Studies": [
    {
      id: "WV.SS.5.20.b",
      description:
        "Explain how specific events and issues led to the Civil War (e.g., sectionalism fueled by issues of slavery in the territories, states’ rights, election of 1860 and secession).",
      optionalText: "",
    },
    {
      id: "WV.SS.5.20.c",
      description:
        "Summarize key battles, strategies and turning points of the Civil War (e.g., Fort Sumter, Antietam, Gettysburg, other regional battles and the surrender at Appomattox).",
      optionalText: "",
    },
  ],
};

const StandardsModal = ({ onClose, onStandardsSelected, elaStandards, socialStudiesStandards, onSkip, isFromFollowup, gradeLevel }) => {
  const [activeTab, setActiveTab] = useState("ELA");
  const [searchText, setSearchText] = useState("");
  const [data] = useState(initialData);
  const [selectedStandards, setSelectedStandards] = useState({});
  const modalRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose();
      }
    };

    if (inputRef.current) {
      inputRef.current.focus();
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClose = () => {
    setSearchText("");
    setSelectedStandards({});
    onClose();
  };

  const handleSocialStudiesToggle = (standard) => {
    setSelectedStandards((prev) => {
      let output = {}
      if (prev[standard.id]) {
        return output;
      }
      // Only one standard at a time, since they are topical
      output = {
        [standard.id]: {
          ...standard,
          optionalText: "",
          skills: [],
          type: 'social_studies',
        }
      }
      return output
    })
  };

  const handleOptionalTextChange = (standardId, text) => {
    setSelectedStandards((prev) => ({
      ...prev,
      [standardId]: { ...prev[standardId], optionalText: text },
    }));
  };

  const handleELASkillToggle = (standard, skill) => {
    const standardId = standard.id;
    setSelectedStandards((prev) => {
      const currentSkills = prev[standardId]?.skills || [];
      const newSkills = currentSkills.includes(skill)
        ? currentSkills.filter((s) => s !== skill)
        : [...currentSkills, skill];

      return {
        ...prev,
        [standardId]: { 
          ...prev[standardId], skills: newSkills, standard_set: standard.standard_set, type: 'ela'
        },
      };
    });
  };

  const isAddButtonDisabled = !Object.values(selectedStandards).some(
    (entry) => (activeTab === 'ELA' && entry.skills?.length) || activeTab === 'Soc. Studies'
  );

  const filteredData = () => {
    const dataSet = activeTab === 'ELA' ? elaStandards : socialStudiesStandards;
    return dataSet.filter(standard => {
      const standardSkills = standard.skills || []
      const searchTextLower = searchText.toLowerCase()
      return (standardSkills.some(skill => skill?.toLowerCase().includes(searchTextLower)) 
        || standard?.description?.toLowerCase().includes(searchTextLower)) && standard?.grade_level.indexOf(gradeLevel) > -1
    })
  }

  const onAddSkill = () => {
    onStandardsSelected(selectedStandards);
    handleClose();
  };

  const hasMultipleSkillsSelected = () => {
    let skillsSelected = [];
    Object.values(selectedStandards).forEach((standard) => {
      if (standard.skills) {
        skillsSelected = [...skillsSelected, ...standard.skills];
      }
    })
    return skillsSelected.length > 1
  }

  const containsSearchText = (text) => {
    if (!searchText) return false;
    return text.toLowerCase().includes(searchText.toLowerCase())
  }

  return (
    <div className="modal-overlay bg-gray-900 bg-opacity-50 fixed inset-0 flex justify-center items-center">
      <div
        className="bg-white rounded-lg p-6 w-3/4 max-w-3xl h-[70vh] flex flex-col position-relative"
        ref={modalRef}
      >
        {!isFromFollowup && <button
          type="button"
          className="btn-close position-absolute top-0 end-0 m-3"
          onClick={onClose}
          aria-label="Close"
        ></button>}

        <h2 className="text-xl font-semibold mb-4 text-center">
          What skills and standards are you teaching?
        </h2>

        <div className="flex items-center border focus-within:border-2 focus-within:border-emerald-500 border-gray-200 rounded-full pl-4 pr-2 py-1 mb-4">
          <input
            ref={inputRef}
            type="text"
            className="flex-1 text-sm text-gray-600 focus:outline-none"
            placeholder="Search by keywords or standard ID"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <div className="flex space-x-2 ml-4 border border-gray-200 rounded-full">
            {["ELA", "Soc. Studies"].map((tab) => (
              <button
                key={tab}
                className={`px-4 py-2 rounded-full text-sm ${
                  activeTab === tab
                    ? "bg-gray-200 text-black font-semibold"
                    : "text-gray-300"
                }`}
                onClick={() => {
                  setSelectedStandards({})
                  setActiveTab(tab)
                }}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        <div className="overflow-y-scroll flex-grow border rounded">
          {filteredData().map((standard) => (
            <div key={standard.id} className={`border-b p-4 `
              .concat(activeTab === 'Soc. Studies' ? ' hover:bg-gray-100 cursor-pointer' : '')
              .concat(activeTab === 'Soc. Studies' && selectedStandards[standard.id] ? ' bg-gray-100' : '')}
              onClick={() => activeTab === 'Soc. Studies' ? handleSocialStudiesToggle(standard) : null}>
              <div className="mb-4">
                <div className="flex flex-row">
                  {activeTab === 'Soc. Studies' && 
                    <button onClick={() => handleSocialStudiesToggle(standard)}>
                    <span
                    className={`w-4 h-4 flex items-center justify-center rounded-full border mr-2 ${
                      selectedStandards[standard.id]
                        ? "border-emerald-500 text-white text-xs bg-emerald-500"
                        : "border-emerald-500 text-emerald-500"
                    }`}
                  >
                    {selectedStandards[standard.id] 
                      ? <XMarkIcon className="w-3 h-3" />
                      : <PlusIcon className="w-3 h-3" />}
                  </span>
                    </button>}
                  <div className="font-bold">{standard.id}</div>
                </div>
                <div className="text-sm text-gray-600">
                  {standard.description}
                </div>
              </div>
              {activeTab === "Soc. Studies" ? (
                <>
                  {selectedStandards[standard.id] && (
                    <input
                      type="text"
                      className="w-full mt-2 text-sm text-gray-600 border border-gray-300 rounded-lg px-3 py-2 focus:outline-none z-50"
                      placeholder="What are you focusing on? (optional)"
                      value={selectedStandards[standard.id]?.optionalText || ""}
                      onClick={(e) => e.stopPropagation()}
                      onChange={(e) => {
                        handleOptionalTextChange(standard.id, e.target.value)
                      }}
                    />
                  )}
                </>
              ) : (
                <div className="flex flex-wrap gap-3">
                  {standard.skills?.map((skill) => (
                    <button
                      key={skill}
                      className={`flex items-center gap-3 px-2 py-1 text-sm rounded-lg border ${
                        selectedStandards[standard.id]?.skills?.includes(skill)
                          ? "border-green-500 text-white bg-green-500" : 
                            containsSearchText(skill) ? "bg-green-100" : "bg-gray-50 border-gray-300 text-gray-700"
                      }`}
                      onClick={() => handleELASkillToggle(standard, skill)}
                    >
                      <span
                        className={`w-4 h-4 flex items-center justify-center rounded-full border ${
                          selectedStandards[standard.id]?.skills?.includes(
                            skill
                          )
                            ? "border-white text-white text-xs"
                            : "border-green-500 text-green-500"
                        }`}
                      >
                        {selectedStandards[standard.id]?.skills?.includes(skill)
                          ? <XMarkIcon className="w-3 h-3" />
                          : <PlusIcon className="w-3 h-3" />}
                      </span>
                      {skill}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-4 flex justify-center">
          {isFromFollowup &&
            <button
              className={`border-2 font-semibold border-green-600 text-green-600 px-8 py-1.5 mr-8 rounded-lg text-gray-700 hover:text-gray-500`}
              onClick={onSkip}
            >
              Skip
            </button>
          }
          <button
            className={`px-8 py-1.5 rounded-lg text-white ${
              isAddButtonDisabled
                ? "bg-gray-200 cursor-not-allowed"
                : "bg-emerald-500 hover:bg-emerald-600"
            }`}
            disabled={isAddButtonDisabled}
            onClick={onAddSkill}
          >
            Add {hasMultipleSkillsSelected() ? "Skills" : "Skill"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default StandardsModal;
