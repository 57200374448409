import React, { useState, useRef, useEffect } from "react";

function ChapterSelection({ bookTitle, onChapterSelect, onSkip }) {
  const [chapterNumber, setChapterNumber] = useState("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSkip]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (chapterNumber) {
      onChapterSelect(chapterNumber);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onSkip}
            aria-label="Close"
          ></button>
          
          <h2 className="text-center mb-4">
            Which chapter of <span className="text-success">{bookTitle}</span>?
          </h2>

          <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
            <div className="mb-4 w-100" style={{ maxWidth: "300px" }}>
              <input
                ref={inputRef}
                type="number"
                className="search-input text-center"
                placeholder="Enter chapter number..."
                value={chapterNumber}
                onChange={(e) => setChapterNumber(e.target.value)}
                min="1"
                autoComplete="off"
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!chapterNumber}
              >
                Select Chapter
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChapterSelection;
