// useArtificialLogin.js
import { useState, useEffect } from 'react';
import { useSession, useSignIn, useAuth, useClerk } from "@clerk/clerk-react";
import { setAxiosToken } from "./services/DiffitService"; // Adjust the import as needed

const useArtificialLogin = () => {
  const { isLoaded } = useAuth();
  const { signIn } = useSignIn();
  const { session } = useSession();
  const clerk = useClerk();

  const [clerkSessionReady, setClerkSessionReady] = useState(false);

  useEffect(() => {
    const handleLogin = async () => {
      if (!isLoaded) {
        console.log("Clerk is not fully loaded yet.");
        return;
      }

      try {
        if (session) {
          const token = await session.getToken({ template: 'userinfo' });
          setAxiosToken(token);
          setClerkSessionReady(true);
          return;
        }

        // Wait for a short delay to ensure Clerk is ready
        await new Promise(resolve => setTimeout(resolve, 1000));

        const emailAddress = "eric+ue.prototype@diffit.me";
        const password = "nag6pke*myx!fkq4AND";

        const signInAttempt = await signIn.create({
          strategy: "password",
          identifier: emailAddress,
          password,
        });

        if (signInAttempt.status === "complete") {
          await clerk.setActive({ session: signInAttempt.createdSessionId });
          const newSession = await clerk.session.getToken({ template: 'userinfo' });
          setAxiosToken(newSession);
          setClerkSessionReady(true);
        } else {
          console.log("Further steps required for sign-in:", signInAttempt);
        }
      } catch (error) {
        console.error("Error during artificial sign-in:", error);
      }
    };

    handleLogin();
  }, [isLoaded, session]);

  return { clerkSessionReady };
};

export default useArtificialLogin;