import React, { useState, useRef, useEffect } from "react";
import suggestions from "../data/suggestions.json";

function BookInputModal({ onBookSelect, onSkip }) {
  const [bookTitle, setBookTitle] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [matchingSuggestions, setMatchingSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onSkip]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [matchingSuggestions]);

  useEffect(() => {
    if (suggestionsRef.current && showSuggestions) {
      const selectedElement = suggestionsRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: "nearest" });
      }
    }
  }, [selectedIndex, showSuggestions]);

  const filterSuggestions = (query) => {
    if (!query || query.length < 2) return [];

    const matches = [];
    const lowercaseQuery = query.toLowerCase();
    const allBooks = suggestions?.content || [];

    const exactMatches = allBooks.filter(
      (book) => book.toLowerCase() === lowercaseQuery,
    );

    const startsWithMatches = allBooks.filter(
      (book) =>
        book.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some((exact) => exact === book),
    );

    const containsMatches = allBooks.filter(
      (book) =>
        book.toLowerCase().includes(lowercaseQuery) &&
        !book.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some((exact) => exact === book),
    );

    const allMatches = [
      ...exactMatches,
      ...startsWithMatches,
      ...containsMatches,
    ];

    if (allMatches.length > 0) {
      matches.push({
        text: `${query}`,
        type: "input-echo",
        category: "content",
      });
      matches.push({ type: "separator" });
    }

    matches.push(
      ...allMatches.map((book) => ({
        text: book,
        category: "content",
      })),
    );

    return matches;
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setBookTitle(value);

    const matches = filterSuggestions(value);
    setMatchingSuggestions(matches);
    setShowSuggestions(value.length >= 2 && hasActualSuggestions(matches));
  };

  const hasActualSuggestions = (matches) => {
    return matches.some(
      (match) =>
        match.type !== "separator" &&
        (!match.type || match.type === "input-echo"),
    );
  };

  const findNextSelectableIndex = (currentIndex, direction = 1) => {
    let nextIndex = currentIndex;
    do {
      nextIndex =
        (nextIndex + direction + matchingSuggestions.length) %
        matchingSuggestions.length;
    } while (
      nextIndex !== currentIndex &&
      matchingSuggestions[nextIndex]?.type === "separator"
    );
    return nextIndex;
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions || !matchingSuggestions.length) {
      if (e.key === "Enter" && bookTitle.trim()) {
        e.preventDefault();
        handleSubmit(e);
      }
      return;
    }

    switch (e.key) {
      case "ArrowDown":
        e.preventDefault();
        const nextIndex = findNextSelectableIndex(selectedIndex);
        if (matchingSuggestions[nextIndex]?.type !== "input-echo") {
          setSelectedIndex(nextIndex);
        } else {
          setSelectedIndex(findNextSelectableIndex(nextIndex));
        }
        break;
      case "ArrowUp":
        e.preventDefault();
        setSelectedIndex(findNextSelectableIndex(selectedIndex, -1));
        break;
      case "Enter":
        e.preventDefault();
        if (
          matchingSuggestions[selectedIndex] &&
          matchingSuggestions[selectedIndex].type !== "separator"
        ) {
          handleBookSelect(matchingSuggestions[selectedIndex]);
        } else if (bookTitle.trim()) {
          handleSubmit(e);
        }
        break;
      case "Escape":
        e.preventDefault();
        onSkip();
        break;
      case "Tab":
        if (
          matchingSuggestions[selectedIndex] &&
          matchingSuggestions[selectedIndex].type !== "separator"
        ) {
          e.preventDefault();
          setBookTitle(matchingSuggestions[selectedIndex].text);
          setShowSuggestions(false);
        }
        break;
    }
  };

  const handleBookSelect = (suggestion) => {
    if (suggestion.type === "separator") return;

    if (suggestion.type === "input-echo") {
      onBookSelect({ text: suggestion.text, category: "content" });
      return;
    }

    onBookSelect({ text: suggestion.text, category: "content" });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (bookTitle.trim()) {
      onBookSelect({ text: bookTitle.trim(), category: "content" });
    }
  };

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center modal-overlay"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}
    >
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div
          className="search-container bg-white position-relative"
          ref={modalRef}
        >
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onSkip}
            aria-label="Close"
          ></button>

          <h2 className="text-center mb-4">Enter Book Title</h2>

          <form onSubmit={handleSubmit}>
            <div className="position-relative mb-4">
              <input
                ref={inputRef}
                type="text"
                className="search-input"
                placeholder="Enter book title..."
                value={bookTitle}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />

              {showSuggestions && matchingSuggestions.length > 0 && (
                <div
                  ref={suggestionsRef}
                  className="suggestions-container position-absolute w-100 mt-2"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  {matchingSuggestions.map((suggestion, index) => {
                    if (suggestion.type === "separator") {
                      return (
                        <div key={index} className="suggestion-separator"></div>
                      );
                    }
                    if (suggestion.type === "input-echo") {
                      return (
                        <div
                          key={index}
                          className={`suggestion-item input-echo ${index === selectedIndex ? "highlighted" : ""}`}
                          onClick={() => handleBookSelect(suggestion)}
                          onMouseEnter={() => setSelectedIndex(index)}
                        >
                          {suggestion.text}
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        className={`suggestion-item ${index === selectedIndex ? "highlighted" : ""}`}
                        onClick={() => handleBookSelect(suggestion)}
                        onMouseEnter={() => setSelectedIndex(index)}
                      >
                        <i className="fa-solid fa-book me-2"></i>
                        {suggestion.text}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!bookTitle.trim()}
              >
                Add Book
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default BookInputModal;
