import React, { useState, useRef, useEffect } from "react";

function LinkModal({ onUrlSubmit, onSkip }) {
  const [url, setUrl] = useState("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSkip]);

  const validateUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onUrlSubmit({ text: url.trim(), category: "link" });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      onSkip();
    } else if (e.key === "Enter" && url.trim() && validateYouTubeUrl(url)) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center modal-overlay"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}
    >
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onSkip}
            aria-label="Close"
          ></button>

          <h3 className="text-center mb-6">What webpage, article, or YouTube are you teaching?</h3>

          <form onSubmit={handleSubmit}>
            <div className="position-relative mb-4">
              <input
                ref={inputRef}
                type="text"
                className="search-input"
                placeholder="Paste YouTube URL here..."
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!url.trim() || !validateUrl(url)}
              >
                Add Link
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LinkModal;
