import React, { useState, useRef, useEffect } from "react";

function PDFUploadModal({ onPDFSelect, onSkip }) {
  const [pdfFile, setPdfFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [pdfLink, setPdfLink] = useState("");
  const fileInputRef = useRef(null);
  const linkInputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (linkInputRef.current) {
      linkInputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSkip]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      setPdfFile(file);
      setFileName(file.name);
      setPdfLink(""); // Clear link when file is selected
    }
  };

  const handleLinkChange = (e) => {
    setPdfLink(e.target.value);
    setPdfFile(null); // Clear file when link is entered
    setFileName("");
  };

  const validatePDFLink = (url) => {
    return url.trim().toLowerCase().endsWith('.pdf');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (pdfFile) {
      onPDFSelect({ text: fileName, category: "content", type: "pdf", file: pdfFile });
      onSkip(); // Close modal after successful file upload
    } else if (pdfLink && validatePDFLink(pdfLink)) {
      onPDFSelect({ text: pdfLink, category: "content", type: "pdf", url: pdfLink });
      onSkip(); // Close modal after successful link submission
    }
  };

  // Get the appropriate button text based on the current state
  const getButtonText = () => {
    if (pdfFile) return "Upload PDF";
    if (pdfLink && validatePDFLink(pdfLink)) return "Add PDF Link";
    return "Add PDF";
  };

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center modal-overlay"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}
    >
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onSkip}
            aria-label="Close"
          ></button>

          <h2 className="text-center mb-4">Enter a Link or Upload a PDF</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                ref={linkInputRef}
                type="url"
                className="search-input mb-3"
                placeholder="Enter PDF link..."
                value={pdfLink}
                onChange={handleLinkChange}
                autoComplete="off"
              />
              
              <div className="text-center">
                <label className="d-block mb-3">
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="d-none"
                  />
                  <div className="filter-button d-inline-block">
                    <i className="fa-solid fa-file-pdf me-2"></i>
                    Choose PDF File
                  </div>
                </label>
                {fileName && (
                  <div className="text-success">Selected: {fileName}</div>
                )}
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!pdfFile && (!pdfLink || !validatePDFLink(pdfLink))}
              >
                {getButtonText()}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PDFUploadModal;
