import React, { useState, useRef, useEffect } from "react";

function ReadingLevelSelection({ onLevelSelect, onSkip }) {
  const [level, setLevel] = useState("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onSkip]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (level.trim()) {
      onLevelSelect(level.trim());
    }
  };

  const handleQuickSelect = (gradeLevel) => {
    onLevelSelect(gradeLevel);
  };

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center modal-overlay"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}
    >
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div
          className="search-container bg-white position-relative"
          ref={modalRef}
        >
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onSkip}
            aria-label="Close"
          ></button>

          <h2 className="text-center mb-4">Select Reading Level</h2>

          <form onSubmit={handleSubmit}>
            <div className="d-flex flex-wrap gap-3 justify-content-center mt-4 mb-4">
              {[
                "1st",
                "2nd",
                "3rd",
                "4th",
                "5th",
                "6th",
                "7th",
                "8th",
                "9th",
                "10th",
                "11th",
                "12th",
              ].map((grade) => (
                <button
                  key={grade}
                  type="button"
                  className="filter-button"
                  onClick={() => handleQuickSelect(`${grade} Grade`)}
                >
                  <i className="fa-solid fa-book-open"></i> {grade} Grade
                </button>
              ))}
            </div>

            <div className="d-flex justify-content-center mt-4">
              <button
                type="submit"
                className="generate-btn"
                disabled={!level.trim()}
              >
                Set Level
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ReadingLevelSelection;
