import React, { useRef, useEffect } from "react";

function TextExcerptModal({ onTextSelect, onClose, text }) {
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onClose]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = text
    }
  }, [text])

  const handleSubmit = (e) => {
    e.preventDefault();
    const text = inputRef.current.value.trim();
    if (text) {
      onTextSelect({ text, category: "text" });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={onClose}
            aria-label="Close"
          ></button>

          <h2 className="text-center mb-4">What text are you teaching?</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <textarea
                ref={inputRef}
                className="search-input"
                placeholder="Paste up to 40,000 words..."
                style={{ minHeight: "200px", resize: "vertical" }}
                autoComplete="off"
              />
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
              >
                Add Pasted Text
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TextExcerptModal;
