import React, { useState, useRef, useEffect } from "react";

function VideoURLModal({ onVideoSelect, onSkip }) {
  const [videoUrl, setVideoUrl] = useState("");
  const [error, setError] = useState("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        handleClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleClose = () => {
    setVideoUrl("");
    setError("");
    onSkip();
  };

  const validateYouTubeUrl = (url) => {
    const pattern = /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/.+$/;
    return pattern.test(url);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateYouTubeUrl(videoUrl)) {
      onVideoSelect({ text: videoUrl.trim(), category: "content", type: "video" });
      setVideoUrl("");
      setError("");
    } else {
      setError("Please enter a valid YouTube URL");
    }
  };

  return (
    <div className="modal-overlay">
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={handleClose}
            aria-label="Close"
          ></button>

          <h2 className="text-center mb-4">Add YouTube Video</h2>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <input
                ref={inputRef}
                type="url"
                className="search-input"
                placeholder="Enter YouTube video URL..."
                value={videoUrl}
                onChange={(e) => {
                  setVideoUrl(e.target.value);
                  setError("");
                }}
                autoComplete="off"
              />
              {error && (
                <div className="text-danger mt-2 text-center">{error}</div>
              )}
            </div>

            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!videoUrl.trim()}
              >
                Add Video
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default VideoURLModal;
