import React, { useState, useRef, useEffect } from "react";

function GradeSelectModal({ onGradeSelect }) {
  const [gradeLevel, setGradeLevel] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const inputRef = useRef(null);
  const modalRef = useRef(null);

  const stateMapping = {
    'AL': 'Alabama', 'AK': 'Alaska', 'AZ': 'Arizona', 'AR': 'Arkansas', 'CA': 'California',
    'CO': 'Colorado', 'CT': 'Connecticut', 'DE': 'Delaware', 'FL': 'Florida', 'GA': 'Georgia',
    'HI': 'Hawaii', 'ID': 'Idaho', 'IL': 'Illinois', 'IN': 'Indiana', 'IA': 'Iowa',
    'KS': 'Kansas', 'KY': 'Kentucky', 'LA': 'Louisiana', 'ME': 'Maine', 'MD': 'Maryland',
    'MA': 'Massachusetts', 'MI': 'Michigan', 'MN': 'Minnesota', 'MS': 'Mississippi',
    'MO': 'Missouri', 'MT': 'Montana', 'NE': 'Nebraska', 'NV': 'Nevada', 'NH': 'New Hampshire',
    'NJ': 'New Jersey', 'NM': 'New Mexico', 'NY': 'New York', 'NC': 'North Carolina',
    'ND': 'North Dakota', 'OH': 'Ohio', 'OK': 'Oklahoma', 'OR': 'Oregon', 'PA': 'Pennsylvania',
    'RI': 'Rhode Island', 'SC': 'South Carolina', 'SD': 'South Dakota', 'TN': 'Tennessee',
    'TX': 'Texas', 'UT': 'Utah', 'VT': 'Vermont', 'VA': 'Virginia', 'WA': 'Washington',
    'WV': 'West Virginia', 'WI': 'Wisconsin', 'WY': 'Wyoming'
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (gradeLevel && selectedState) {
      onGradeSelect({ grade: gradeLevel, state: selectedState });
    }
  };

  return (
    <div className="modal-overlay">
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          
          <h2 className="text-center mb-4">
            Select the grade and state for which you need materials
          </h2>

          <form onSubmit={handleSubmit} className="d-flex flex-column align-items-center">
            <div className="mb-4 w-100" style={{ maxWidth: "300px" }}>
              <select
                ref={inputRef}
                className="search-input text-center mb-3"
                value={gradeLevel}
                onChange={(e) => setGradeLevel(e.target.value)}
                autoComplete="off"
              >
                <option value="" disabled>Select a grade level...</option>
                <option value="3">3rd grade</option>
                <option value="4">4th grade</option>
                <option value="5">5th grade</option>
              </select>

              <select
                className="search-input text-center"
                value={selectedState}
                onChange={(e) => setSelectedState(e.target.value)}
                autoComplete="off"
              >
                <option value="" disabled>Select a state...</option>
                {Object.entries(stateMapping).map(([code, name]) => (
                  <option key={code} value={code}>
                    {name}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className="generate-btn"
                disabled={!gradeLevel || !selectedState}
              >
                Select Grade and State
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GradeSelectModal;
