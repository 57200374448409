import React, { useState, useRef, useEffect } from "react";
import suggestions from '../data/suggestions.json';

function TopicInputModal({ onTopicSelect, onSkip, onClose, isFromFollowup, createResourcesAndRedirectToPrototypeOutput }) {
  const [topic, setTopic] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [matchingSuggestions, setMatchingSuggestions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isCreatingTopic, setIsCreatingTopic] = useState(false);
  const inputRef = useRef(null);
  const modalRef = useRef(null);
  const suggestionsRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }

    const handleClickOutside = (e) => {
      if (modalRef.current && !modalRef.current.contains(e.target)) {
        onSkip();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [onSkip]);

  useEffect(() => {
    setSelectedIndex(0);
  }, [matchingSuggestions]);

  useEffect(() => {
    if (suggestionsRef.current && showSuggestions) {
      const selectedElement = suggestionsRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex, showSuggestions]);

  const filterSuggestions = (query) => {
    if (!query || query.length < 2) return [];

    const matches = [];
    const lowercaseQuery = query.toLowerCase();
    const allTopics = suggestions?.topics || [];

    const exactMatches = allTopics.filter(
      topic => topic.toLowerCase() === lowercaseQuery
    );

    const startsWithMatches = allTopics.filter(
      topic => 
        topic.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some(exact => exact === topic)
    );

    const containsMatches = allTopics.filter(
      topic => 
        topic.toLowerCase().includes(lowercaseQuery) &&
        !topic.toLowerCase().startsWith(lowercaseQuery) &&
        !exactMatches.some(exact => exact === topic)
    );

    const allMatches = [
      ...exactMatches,
      ...startsWithMatches,
      ...containsMatches
    ];

    if (allMatches.length > 0) {
      matches.push({
        text: `${query}`,
        type: "input-echo",
        category: "topics"
      });
      matches.push({ type: "separator" });
    }

    matches.push(...allMatches.map(topic => ({
      text: topic,
      category: "topics"
    })));

    return matches;
  };

  const hasActualSuggestions = (matches) => {
    return matches.some(
      match => match.type !== "separator" && (!match.type || match.type === "input-echo")
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setTopic(value);

    const matches = filterSuggestions(value);
    setMatchingSuggestions(matches);
    setShowSuggestions(value.length >= 2 && hasActualSuggestions(matches));
  };

  const findNextSelectableIndex = (currentIndex, direction = 1) => {
    let nextIndex = currentIndex;
    do {
      nextIndex = (nextIndex + direction + matchingSuggestions.length) % matchingSuggestions.length;
    } while (
      nextIndex !== currentIndex &&
      matchingSuggestions[nextIndex]?.type === "separator"
    );
    return nextIndex;
  };

  const handleKeyDown = (e) => {
    if (!showSuggestions || !matchingSuggestions.length) {
      if (e.key === 'Enter' && topic.trim()) {
        e.preventDefault();
        handleSubmit(e);
      }
      return;
    }

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        const nextIndex = findNextSelectableIndex(selectedIndex);
        if (matchingSuggestions[nextIndex]?.type !== "input-echo") {
          setSelectedIndex(nextIndex);
        } else {
          setSelectedIndex(findNextSelectableIndex(nextIndex));
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedIndex(findNextSelectableIndex(selectedIndex, -1));
        break;
      case 'Enter':
        e.preventDefault();
        if (matchingSuggestions[selectedIndex] && matchingSuggestions[selectedIndex].type !== "separator") {
          handleTopicSelect(matchingSuggestions[selectedIndex]);
        } else if (topic.trim()) {
          handleSubmit(e);
        }
        break;
      case 'Escape':
        e.preventDefault();
        onSkip();
        break;
      case 'Tab':
        if (matchingSuggestions[selectedIndex] && matchingSuggestions[selectedIndex].type !== "separator") {
          e.preventDefault();
          setTopic(matchingSuggestions[selectedIndex].text);
          setShowSuggestions(false);
        }
        break;
    }
  };

  const handleTopicSelect = (suggestion) => {
    if (suggestion.type === "separator") return;

    setShowSuggestions(false);
    setTopic(suggestion.text);

    if (isFromFollowup) {
      setIsCreatingTopic(true)
    }
    
    if (suggestion.type === "input-echo") {
      onTopicSelect({ text: suggestion.text, category: "topics" });
      return;
    }

    onTopicSelect({ text: suggestion.text, category: "topics" });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (topic.trim()) {
      onTopicSelect({ text: topic.trim(), category: "topics" });
    }
  };

  return (
    <div
      className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center modal-overlay"
      style={{ backgroundColor: "rgba(0,0,0,0.5)", zIndex: 1050 }}
    >
      <div className="container mx-auto px-4" style={{ maxWidth: "768px" }}>
        <div className="search-container bg-white position-relative" ref={modalRef}>
          <button
            type="button"
            className="btn-close position-absolute top-0 end-0 m-3"
            onClick={() => {
              if (!isCreatingTopic) {
                onClose()
              }
            }}
            disabled={isCreatingTopic}
            aria-label="Close"
          ></button>

          {!isFromFollowup && <h2 className="text-center mb-4">Enter Topic</h2>}
          {isFromFollowup && <h2 className="text-center mb-4">Do you want resources for a specific topic?</h2>}

          <form onSubmit={handleSubmit}>
            <div className="position-relative mb-4">
              <input
                ref={inputRef}
                type="text"
                className="search-input"
                placeholder="Enter topic..."
                value={topic}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                autoComplete="off"
              />

              {showSuggestions && matchingSuggestions.length > 0 && (
                <div 
                  ref={suggestionsRef}
                  className="suggestions-container position-absolute w-100 mt-2"
                  style={{ maxHeight: '300px', overflowY: 'auto' }}
                >
                  {matchingSuggestions.map((suggestion, index) => {
                    if (suggestion.type === "separator") {
                      return <div key={index} className="suggestion-separator"></div>;
                    }
                    if (suggestion.type === "input-echo") {
                      return (
                        <div
                          key={index}
                          className={`suggestion-item input-echo ${index === selectedIndex ? "highlighted" : ""}`}
                          onClick={() => handleTopicSelect(suggestion)}
                          onMouseEnter={() => setSelectedIndex(index)}
                        >
                          {suggestion.text}
                        </div>
                      );
                    }
                    return (
                      <div
                        key={index}
                        className={`suggestion-item ${index === selectedIndex ? 'highlighted' : ''}`}
                        onClick={() => handleTopicSelect(suggestion)}
                        onMouseEnter={() => setSelectedIndex(index)}
                      >
                        <i className="fa-solid fa-globe me-2"></i>
                        {suggestion.text}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
            <div className="mt-4 flex justify-center">
              {isFromFollowup && <button
                  className={`border-2 font-semibold border-green-600 text-green-600 px-8 py-1.5 mr-8 rounded-lg text-gray-700 hover:text-gray-500`
                    .concat(isCreatingTopic ? "cursor-not-allowed" : "")}
                  onClick={onSkip}
                  disabled={isCreatingTopic}
                >
                  Skip
                </button>}
              <button
                type="submit"
                className={`px-8 py-1.5 rounded-lg text-white ${
                  !topic.trim() || isCreatingTopic
                    ? "bg-gray-200 cursor-not-allowed"
                    : "bg-emerald-500 hover:bg-emerald-600"
                }`}
                disabled={!topic.trim() || isCreatingTopic}
              >
                {isCreatingTopic ? 'Creating...' : 'Add Topic' }
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default TopicInputModal;
